import React, { useRef } from 'react';
import { ActionManager } from '@babylonjs/core/Actions/actionManager';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera';
import { Vector3 } from '@babylonjs/core/Maths/math';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import BabylonScene from 'babylonjs-hook';
import '../layout.css';

import {
    AnimationPropertiesOverride, CombineAction,
    ExecuteCodeAction, FollowCamera,
    InterpolateValueAction, SetValueAction,
    StandardMaterial,
    Texture, Tools, TransformNode, UniversalCamera, GlowLayer
} from "@babylonjs/core";
import {Color3} from "@babylonjs/core/Maths/math";
import {PointLight} from "@babylonjs/core";

import {ArcRotateCamera} from "@babylonjs/core";
import {SceneLoader} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import {Link} from "gatsby";
// import {GUI} from "@babylonjs/gui";
//import {GUI} from "@babylonjs/core";

let box;
let babylonLink;




const onSceneReady = scene => {

    // scene.clearColor = new Color3(0.5, 0.8, 0.5);
    // scene.clearColor = new Color3(73, 80, 87); was this one
    scene.clearColor = new Color3(0.0234375,0.0234375,0.0234375); // CHECK

    const canvas = scene.getEngine().getRenderingCanvas();

    const camera = new ArcRotateCamera("cam", -Math.PI , Math.PI / 2, 5, new Vector3(0,0,0));
    const anchor = new TransformNode("");

    camera.wheelDeltaPercentage = 0.05;
    camera.lowerRadiusLimit = 3.5;
    camera.upperRadiusLimit = 6;
    // camera.lowerBetaLimit = -Math.PI + -Math.PI /2;



    var gl = new GlowLayer("glow", scene);
    gl.intensity = 0.77;


    camera.attachControl(canvas, true);


    // Main Panel
    const manager = new GUI.GUI3DManager(scene);
    // SpherePanelを生成
    const panel = new GUI.SpherePanel();
    panel.margin = 0.4;
    panel.radius = 3.5;

    manager.addControl(panel);
    panel.linkToTransformNode(anchor);
    panel.position.z = 1.2;  // -1.5





    // Materials
    var webMaterial = new StandardMaterial("webmaterial", scene);
    //boxMaterial.emissiveColor = new Color3(0, 0.58, 0.86);
    webMaterial.emissiveColor = new Color3(0.15, 0.15, 0.86);








    const buttonWork = new GUI.HolographicButton("past");
    panel.addControl(buttonWork);

    var textWork = new GUI.TextBlock();
    textWork.text = "Dark";
    textWork.color = "#034067";
    textWork.fontSize = 82;
    textWork.fontWeight = "bold";

    buttonWork.content = textWork;


    buttonWork.onPointerClickObservable.add(function() {

        window.open("/draw-dark/",'_self',false);
    });




    const buttonMaps = new GUI.HolographicButton("future");
    panel.addControl(buttonMaps);

    var textMaps = new GUI.TextBlock();
    textMaps.text = "Light";
    textMaps.color = "#034067";
    textMaps.fontSize = 82;
    textMaps.fontWeight = "bold";

    buttonMaps.content = textMaps;

    buttonMaps.onPointerClickObservable.add(function() {

        window.open("/draw-light/",'_self',false);
    });









    // buttonWeb.actionManager = new ActionManager(scene);
    // buttonWeb.actionManager.registerAction(new ExecuteCodeAction(
    //     ActionManager.onPointerClickObservable ,
    //     function (evt) {
    //
    //         //move the box upright
    //         buttonWeb.position.x += 0.1;
    //         buttonWeb.position.y += 0.1;
    //
    //         window.open("http://twitter.com/");
    //         //update the color
    //
    //     }));

    // const buttonTravel = new GUI.HolographicButton("travel");
    // panel.addControl(buttonTravel);
    // // buttonTravel.text = "Tech";
    // buttonTravel.imageUrl = "../../../images/CroppedPic.png"




    panel.blockLayout = false;




    // panel.actionManager = new ActionManager(scene);
    // panel.actionManager.registerAction(
    //     new ExecuteCodeAction(
    //         ActionManager.OnLongPressTrigger,
    //         () => {
    //             babylonLink.current.click()
    //         }
    //     )
    // );







}

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = scene => {
    // if (box !== undefined) {
    //     var deltaTimeInMillis = scene.getEngine().getDeltaTime();
    //
    //     const rpm = 10;
    //     box.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //     box.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //
    // }

}

export default () => {
    babylonLink = useRef(null);

    return (
        <>
            <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
            <a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://pavli.netlify.app/">
            </a>
            {/*This works*/}
            {/*<BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />*/}
        </>
    )
}
